// import { Link } from "react-router-dom";
// import Navbar from "./Navbar";


export default function Formulaire (){
    return (
        <div>
        <div className="container">
          <div className="tab2">
            <p>FORMULAIRE DE DEMANDE DE PIECE</p>
            </div>
            <hr/>
            <p>Pour tout renseignements complémentaire ou demande de devis, n'hésitez pas à nous contacter au +225 07 77 88 00 82 / +225 07 89 80 08 62.</p>
          <p>Nous restons à votre entière disposition ! Nos Formulaires de Contacts:</p>

          {/* <div className="form-floating mb-3">
           <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
           <label for="floatingInput">Email address</label>
         </div>
         <div className="form-floating">
           <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
           <label for="floatingTextarea2">Messages</label>
         </div>
         <div className="col-md-5">
          <label for="inputAddress" className="form-label">Référence pièce</label>
          <input type="texte" className="form-control" id="inputAddress" placeholder=""/>
        </div>
         <div className="col-md-5">
          <label for="inputAddress" className="form-label">Contacts</label>
          <input type="text" className="form-control" id="inputAddress" placeholder=""/>
        </div> */}
        
        <button>Envoyer</button>
        </div>
        </div>
    );
}