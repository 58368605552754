// src/pages/CartPage.js
import React from 'react';
import Cart from '../components/Cart';

const CartPage = () => {
    return (
        <div>
            {/* <h1>Cart Page</h1> */}
            <Cart />
        </div>
    );
};

export default CartPage;